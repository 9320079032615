@import "src/styles";

.container {
  width: 100%;
  display: flex;
  border-radius: 15px;
  background: $radioPlansInactiveBg;
  border: 2px solid $radioPlansInactiveBorderColor;
  color: $radioPlansColor;
  padding: 20px 10px 20px 15px;
  margin: 8px 0;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &_active {
    background: $radioPlansActiveBg;
    border-color: $radioPlansActiveBorderColor;

    .detail {
      &__title {
        color: $radioPlansActiveTitleColor;
        opacity: 1;
      }

      &__text {
        opacity: 0.8;
      }

      &__subtitle {
        opacity: 0.9;
      }
    }

    .radio {
      &_inside,
      &_outside {
        border-color: $radioPlansActiveColor;
      }

      &_inside {
        background-color: $radioPlansActiveColor;
      }
    }

    .week {
      background: url("icons/rectangle-active.svg");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 10px;
      margin-left: 5px;

      &:before {
        background-color: $radioPlansActiveColor;
      }

      &__title {
        opacity: 1;
      }

      &__text {
        opacity: 1;
      }
    }
  }
}

.radio {
  &_outside {
    border: 2px solid $radioPlansInactiveBorderColor;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    min-width: 26px;
  }

  &_inside {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: transparent;
  }
}

.detail {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  &__titles {
    text-align: start;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
  }

  &__title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize20;
    line-height: $fontSize24;
    opacity: 0.6;
    text-align: start;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__text {
    text-decoration-line: line-through;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize15;
    line-height: $fontSize24;
    opacity: 0.5;
  }

  &__subtitle {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize15;
    line-height: $fontSize24;
    opacity: 0.7;
  }

  &__price {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: $fontSize17;
    line-height: $fontSize24;
    opacity: 0.6;
    margin: 10px 5px 5px 20px;
    text-decoration-line: line-through;
    text-decoration-color: $radioPlansPriceActiveColor;
  }
}

.week {
  padding: 10px;
  min-width: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background: url("icons/rectangle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;

  &__title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: $fontSize24;
    opacity: 0.8;
    width: 80%;
    margin-left: 20px;
  }

  &__text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize12;
    line-height: $fontSize16;
    opacity: 0.8;
    width: 80%;
    margin-left: 20px;
  }
}