@import 'src/styles';
@import 'core/styles/animations/_fadeIn.scss';

.wrap {
  padding: 20px 5px;
  max-width: 400px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $manageBg;
  color: $terminateTextColor;

  .title {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
  }
}