@import 'src/styles';

.wrap {
    position: relative;
    padding: 15px 20px;
    max-width: 500px;
    margin: 0 auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $manageBg;

    .title{
        padding-left: 20px;
    }
}

.bottom {
    display: flex;
    justify-content: center;
    width: 100%;

    .button {
        font-size: $fontSize22;
        line-height: $fontSize24;
    }
}