@import "src/styles";

.wrap {
  width: 100%;
  margin: 6px 0;
  position: relative;
}

.container {
  width: 100%;
  display: flex;
  border-radius: 15px;
  background: $plansInactiveBg;
  border: 2px solid $plansInactiveBorderColor;
  color: $grayOpacity;
  justify-content: space-between;
  padding: 0;
  overflow: hidden;
  box-shadow: $boxShadow;

  & > * {
    transition: $transition;
  }
}

.detailWrap {
  width: calc(100% - 105px);
  display: flex;
  flex-direction: column;
  min-height: 96px;
  justify-content: center;
  padding: 10px 15px;
  font-weight: 600;
  text-align: left;
}

.productTitle {
  font-size: $fontSize20;
  line-height: 1.2;
  color: $plansInactiveTitleColor;
}

.productSubTitle {
  font-size: $fontSize14;
  line-height: 1.71;
  margin-top: 4px;
  font-weight: $fwb;
  color: $plansInactiveTextColor;
}

.lineThrough {
  font-size: $fontSize15;
  font-weight: 700;
  text-decoration-line: line-through;
}

.perWeekWrap {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 105px;
  padding: 5px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: $plansInactiveColor;
  color: $plansInactivePriceColor;
}

.perWeekTitle {
  font-size: $fontSize20;
  line-height: $fontSize32;
  font-weight: 600;
}

.previousPerWeekTitle {
  text-decoration: transparentize($plansBadgeColor, 0.1) line-through;
  text-decoration-thickness: 2px;
  color: $plansInactivePriceColor;
  font-size: $fontSize17;
  font-weight: 600;
}

.perWeekText {
  font-size: $fontSize12;
  line-height: 1.17;
  font-weight: $fwb;
  margin-top: 2px;
}

.lightPerWeekTitle {
  opacity: 0.6;
}

.lightPerWeekText {
  opacity: 0.8;
}

.afterTrialText {
  font-size: $fontSize12;
  font-weight: $fwb;
  opacity: 0.7;
  color: $plansInactiveTextColor;
}

.isActiveContainer {
  background: $plansPrimaryBg;
  border-color: $plansPrimaryBorderColor;

  .productTitle {
    color: $plansPrimaryTitleColor;
  }

  .productSubTitle {
    color: transparentize($plansPrimaryTextColor, 0.1);
  }

  .lineThrough {
    text-decoration-line: line-through;
    color: $plansPrimaryTextColor;
  }

  .afterTrialText {
    color: $plansPrimaryTextColor;
  }

  .perWeekText {
    color: $white;
  }

  .perWeekWrap {
    background-color: $plansPrimaryColor;
    color: $plansPrimaryPriceColor;
  }

  .previousPerWeekTitle {
    text-decoration: $plansBadgeColor line-through;
    color: $plansPrimaryPriceColor;
  }
}
