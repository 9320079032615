@import "src/styles";

.wrap {
  width: 100%;
  margin: 80px auto 50px;
  position: relative;
}

.container {
  width: 92%;
  margin: 0 auto;
  display: flex;
  border-radius: 15px;
  background: $plansInactiveBg;
  border: 2px solid $plansInactiveBorderColor;
  justify-content: center;
  padding: 0;
  box-shadow: $boxShadow;

  & > * {
    transition: $transition;
  }

  &.active {
    background: $plansPrimaryBg;
    border-color: lighten(desaturate($primaryColor, 5), 9);
  }
}

.detailWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 96px;
  justify-content: center;
  padding: 80px 0 30px;
  font-weight: 600;
  text-align: center;
}

.productTitle {
  font-size: $fontSize20;
  font-weight: 700;
  line-height: $fontSize24;
  color: darken(saturate($primaryColor, 33), 17);
  text-align: center;
}

.productSubTitle,
.perWeekTitle {
  font-size: $fontSize18;
  font-weight: 700;
  line-height: 1.71;
  color: $mineShaft;
  text-align: center;
}

.capsulesText,
.perWeekText {
  font-size: $fontSize14;
  font-weight: 400;
  color: $mineShaft;
  opacity: 0.5;
}

.capsulesText {
  padding-top: 10px;
}

.line {
  width: 100%;
  background-color: lighten(desaturate($primaryColor, 9), 49);
  height: 1px;
  margin: 10px 0;
}

.previewWrapper {
  width: 100%;
  position: absolute;
  top: -80px;
  left: 0;
  z-index: 2;
}