@import "src/styles";

.wrap {
  padding: 16px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 32px;
  }

  .btn {
    margin-bottom: 16px;
  }
}
