.react-tel-input .selected-flag .arrow {
  position: absolute;
  left: 22px;
  bottom: 22px;
  background-image: url("../../icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 7px;
  width: 12px;
  border: none;
}

.react-tel-input .selected-flag .arrow.up {
  border: none;
  transform: rotate(180deg);
}