@import 'src/styles';

.wrap {
  max-width: 500px;
  margin: 0 auto;
  padding: 16px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $manageBg;
  color: $terminateTextColor;

  .header_title {
    padding: 0 20px;
  }

  .header {
    margin-bottom: 35px;
  }

  footer {
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100%;
    align-items: center;
  }
}