@import "src/styles";

.wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  .title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: $fontSize15;
    line-height: $fontSize20;
    color: #16786c;
    margin-bottom: 15px;
    margin-top: 20px;
    width: 100%;
  }

  .label {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: $fontSize12;
    line-height: $fontSize16;
    color: #2d2d2d;
  }

  .select {
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: $colorBorderField;
    height: 45px;
    width: 100%;
    background-color: $trans;
    padding: 0 16px;
    color: $mineShaft;

    &__arrow {
      position: absolute;
      right: 13px;
      bottom: 22px;
      background-image: url("icons/arrow-down.svg");
      background-repeat: no-repeat;
      background-size: contain;
      height: 12px;
      width: 12px;
    }
  }

  .input::placeholder,
  .placeholder {
    color: rgb(155, 155, 155);
  }

  .input {
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $colorBorderField;
    height: 45px;
    background-color: $trans;
    padding: 0 16px;
  }

  .item {
    width: 100%;
    position: relative;

    select {
      appearance: none;
      line-height: 1.25;
    }
  }

  .postcode,
  .city {
    width: 47%;
  }

  .phone {
    &__container {
      margin-top: 5px;
      margin-bottom: 10px;
      width: 100%;
      border: 1px solid $colorBorderField;
      border-radius: 4px;
    }

    &__input {
      width: 100% !important;
      border: none !important;
      height: 45px !important;
      background-color: $trans;
      padding: 0 16px 0 48px;
    }

    &__button {
      border: none !important;
      background-color: $white !important;
    }
  }

  .invalid {
    border-color: #e35256;
  }
}
