@import "src/styles";

section.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  max-width: $maxWidthDefault;

  h2 {
    margin-bottom: 24px;
  }

  &.fullScreen {
    max-width: $maxWidthFull;

    .slider {
      margin: -20px -16px 0;
      width: calc(100% + 32px);
    }
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.gap {
      margin-top: 30px;
    }
  }

  .productsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
