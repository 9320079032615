@import "src/styles";

.wrap {
  display: block;
  background: $manageCurrentPlanBg;
  margin-bottom: 32px;

  h2 {
    text-align: center;
    color: $plansPrimaryTextColor;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: $fontSize18;
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    border-top: transparentize($plansPrimaryTextColor, 0.9) solid 0.5px;
    margin-top: 12px;
    padding-top: 12px;

    .left {
      color: $plansPrimaryColor;
    }

    .right {
      color: white;
      text-align: right;
      color: $plansPrimaryTextColor;
      font-weight: 500;

      &.red {
        color: #f27067;
      }
    }
  }
}