@import 'src/styles';

.button_wrap {
  position: relative;
  z-index: 10;
  bottom: 0;
  width: 100%;
  background-color: $fixedWrapperBgColor;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  transition: 200ms;
  padding: 20px;

  &.static_buttons {
    background-color: transparent;
    padding: 0;

    .bottom_button {
      margin-top: 25px;
    }
  }

  &.fix_button {
    position: fixed;

    .bottom_button {
      display: none;
    }
  }

  &.fix_link {
    .bottom_button {
      margin-top: 15px;
      display: block;
    }
  }


  &.hidden_button_wrap {
    background-color: transparent;
    pointer-events: none;

    .main_button {
      pointer-events: auto;
    }

    .bottom_button {
      margin-top: 15px;
      display: block;
      pointer-events: auto;
    }
  }

  &.hidden_button_wrap_fix_link {
    background-color: transparent;
  }
}

.gap {
  width: 100%;
  height: 90px;
}