@import "src/styles";

.content {
  width: 100%;
  margin-top: 16px;
  font-size: $fontSize14;
  font-weight: 600;
  text-align: center;
  line-height: $fontSize20;

  .icon {
    padding-left: 28px;
    line-height: $fontSize24;
  }

  .modalIcon {
    background: url("icons/shield.svg") no-repeat left top;
    display: inline-block;
  }

  .lightIcon {
    background: url("icons/shield.svg") no-repeat left top;
    display: $paymentSafeLight;
  }

  .darkIcon {
    background: url("icons/light-shield.svg") no-repeat left top;
    display: $paymentSafeDark;
  }
}

.modalContent {
  background: image-set("./images/safe.webp" 1x, "./images/safe@2x.webp" 2x, "./images/safe@3x.webp" 3x) no-repeat center bottom;
  display: inline-block;
  color: $mineShaft;
  padding-bottom: 80px;
}

.lightContent {
  background: image-set("./images/safe.webp" 1x, "./images/safe@2x.webp" 2x, "./images/safe@3x.webp" 3x) no-repeat center bottom;
  display: $paymentSafeLight;
  color: $paymentSafeColor;
  padding-bottom: 80px;
}

.darkContent {
  background: image-set("./images/color-safe.webp" 1x, "./images/color-safe@2x.webp" 2x, "./images/color-safe@3x.webp" 3x) no-repeat center bottom;
  display: $paymentSafeDark;
  color: $paymentSafeColor;
  padding-bottom: 30px;
}
