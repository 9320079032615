@import "src/styles";

.wrap {
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $terminateBg;
  color: $terminateTextColor;
}

.top {
  width: calc(100% - 48px);
  max-width: 263px;

  &_appname {
    color: $terminateTitleColor;
    font-size: $fontSize26;
  }
}

.texts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_title {
    font-size: $fontSize22;
    line-height: 1.45;
    letter-spacing: rem(-0.57);
    margin-top: 16px;
  }

  &_text {
    font-size: $fontSize16;
    line-height: 1.38;
    letter-spacing: rem(-0.41);
    margin-top: 10px;
  }
}

.bottom {
  width: 100%;
}
