@import "src/styles";

.wrap {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    position: absolute;
    top: 22px;
    right: 10px;
    padding: 0 10px;
  }

  .description {
    padding: 0 16px;
  }

  .header {
    padding: 0 32px;
    button {
      left: 2px;
    }
  }

  .btn {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
