@import 'src/styles';

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.divider {
  width: 100%;
  height: 1px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -28px;
    width: calc(100% + 56px);
    height: 1px;
    background-color: $colorBorderField;
  }
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  font-weight: $fwb;
}

.productName {
  font-size: $fontSize12;
  color: $gray;
}

.discountTitle {
  font-size: $fontSize17;
  font-weight: $fwb;
  color: $payModalPrimaryColor;
}

.discount {
  background-color: $secondaryColor;
  padding: 10px 16px;
  font-size: $fontSize18;
  font-weight: $fwb;
  line-height: 1;
  border-radius: 6px;
  color: white;
}

.vatText {
  font-weight: 600;
  font-size: $fontSize13;
  line-height: $fontSize18;
  color: $gray;
}

.aboutItemText {
  font-size: $fontSize15;
  color: $gray;
}

.aboutItemTextAccent {
  color: $payModalPrimaryColor;
}

.aboutItemBlackText {
  color: $mineShaft;
  font-size: $fontSize17;

  .lineThrough {
    color: $gray;
    margin-right: 5px;
  }

  .smallText {
    font-size: $fontSize14;
  }
}

.lineThrough {
  text-decoration-line: line-through;
}