@import "src/styles";

.root {
  margin-top: 16px;
  font-size: $fontSize9;
  font-weight: $fwn;
  line-height: 1.54;
  letter-spacing: 0.33px;
  color: $graySpecial;

  a {
    text-decoration: underline;
    color: inherit;
  }

  &.short {
    color: $gray;
    font-size: $fontSize12;
    text-align: center;
    letter-spacing: 0px;
  }

  .more {
    margin-top: 8px;
    display: inline-block;

    .link {
      font-weight: 700;
    }
  }
}
