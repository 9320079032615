@import 'src/styles';

.text {
  font-size: $fontSize14;
  color: $policyCheckBoxTextColor;
  margin: 0 0 5px 0;
  text-align: left;
  line-height: 1.5;
}

.confirmation {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0 0;

  &__label {
    display: flex;
  }

  &__text {
    margin: 0 0 0 30px;
  }

  &__link {
    text-decoration: underline;
    margin-right: 5px;
  }

  &__item {
    margin-right: 5px;
  }
}
