@import "./colors";
@import "./variables";
$primaryColor: #ee8f61;
$secondaryColor: #16786c;

@import "src/styles/colors";

@import "../../manage-subscription/src/manage/styles/theme";
@import "../../manage-subscription/src/terminate/styles/theme";

$payModalPrimaryColor: $primaryColor;

$trans: transparent;

$mainBgColor: #f7f7f7;

$colorError: #f24b57;
$colorBorderField: rgba(184, 185, 195, 0.3);
$inputColor: $gray;
$inputCaretColor: $gray;
$checkboxMarkBackground: $primaryColor;
$checkboxMarkCheckedBackground: $primaryColor;

// questions
$questionTitleBgColor: $white;
$questionTitleColor: $mineShaft;
$questionActiveTitleColor: $primaryColor;
$questionActiveTitleBorderColor: $primaryColor;
$questionButtonBgColor: #142B35;

$fontFamilyPhilosopher: Philosopher, sans-serif;

//shadow & opacity
$blackWithOpacity: rgba(0, 0, 0, 0.5);
$grayOpacity: rgba(45, 45, 45, 0.4);
$boxShadowColor: rgba(183, 181, 170, 0.4);
$boxShadow: 0 1px 4px 0 rgba(183, 181, 170, 0.4);
$boxShadowTitle: 0 1px 2px rgba(0, 0, 0, 0.2);
$shadowColor: rgba(183, 181, 170, 0.3);

// links
$linkColor: #2879d8;

$fontFamily: Open Sans, sans-serif;

$transition: all 0.3s ease;

// button
$buttonBackground: $primaryColor;
$disabledButtonBg: $grayLight;
$disabledButtonColor: $gray;
$buttonFontFamily: $fontFamily;
$buttonFontSize: $fontSize22;
$buttonFontWeight: $fwb;

// title
$titleH1Color: $primaryColor;
$titleH1FontSize: $fontSize26;
$titleH1FontFamily: $fontFamily;
$titleH1FontWeight: $fwb;

$titleH2Color: $primaryColor;
$titleH2FontSize: $fontSize24;
$titleH2FontFamily: $fontFamily;
$titleH2FontWeight: $fwb;

$titleH3Color: $mineShaft;
$titleH3FontSize: $fontSize22;
$titleH3FontFamily: $fontFamily;
$titleH3FontWeight: $fwb;

// preloader
$preloaderColor: $primaryColor;
$preloaderBg: rgba(255, 255, 255, 0.5);

// subscription
$plansPrimaryBg: $white;
$plansInactiveBg: $white;
$plansPrimaryColor: $secondaryColor;
$plansInactiveColor: lighten(desaturate($plansPrimaryColor, 40), 60);
$plansPrimaryBorderColor: $secondaryColor;
$plansInactiveBorderColor: $trans;
$plansPrimaryTitleColor: $secondaryColor;
$plansInactiveTitleColor: inherit;
$plansPrimaryTextColor: $titleH3Color;
$plansInactiveTextColor: inherit;
$plansPrimaryPriceColor: $white;
$plansInactivePriceColor: lighten(desaturate($plansPrimaryColor, 40), 20);
$plansBadgeColor: $primaryColor;
$fixedButtonBoxBg: $white;
$fixedButtonDiscountColor: $secondaryColor;
$policyCheckBoxTextColor: $mineShaft;
$reservedPriceColor: $secondaryColor;

$radioPlansInactiveBg: transparent;
$radioPlansInactiveBorderColor: #1E3B48;
$radioPlansActiveBg: #2B5567;
$radioPlansActiveBorderColor: $white;
$radioPlansActiveTitleColor: $secondaryColor;
$radioPlansActiveColor: $primaryColor;
$radioPlansPriceActiveColor: $secondaryColor;
$radioPlansWeekInactiveBg: $primaryColor;
$radioPlansColor: $white;

// select payment method
$spmTitleColor: #F2E6C4;
$spmSelectButtonNotActiveBg: #1E3B48;
$spmSelectButtonNotActiveBorder: #376478;
$spmSelectButtonNotActiveColor: rgba(255, 255, 255, 0.8);
$spmSelectButtonActiveBg: #FFFFFF;
$spmSelectButtonActiveBorder: #FFFFFF;
$spmSelectButtonActiveColor: #142B35;
$spmSelectIconActiveColor: #27BAA7;
$spmDownloadsBg: #1E3B48;
$spmOrBg: #142B35;
$spmDownloadsLogoColor: #FFFFFF;
$spmDownloadsFooterColor: #FFFFFF;
$spmDownloadsFooterLineColor: #2B5567;
$spmPaymentDescriptionColor: rgba(255, 255, 255, 0.4);
$spmCardButtonBg: linear-gradient(2.59deg, #00EEDB -77.77%, #27BAA7 144.46%), #EE8F61;
$spmCardButtonColor: #FFFFFF;
$spmCardLabelColor: #FFFFFF;
$spmFontFamily: $fontFamily;

// full payment modal
$fpmBackgroundColor: #1E3B48;
$fpmFontFamily: $fontFamily;

//terms-menu
$sideBarDarkButtonColor: #F2E6C4;
$sideBarDarkBgColor: #1E3B48;
$sideBarLightBackDropBg: rgba(47, 47, 47, 0.8);
$sideBarDarkBackDropBg: rgba(21, 43, 53, 0.8);

// stars section
$storeStatsBlockBorder: 1px dashed #cbe7e2;
$storeStatsStarColor: $primaryColor;
$feedbackStarColor: $primaryColor;

// signboard
$warningTextColor: #EE8F61;
$warningBgColor: #FDEEE4;

$successTextColor: #2E897F;
$successBgColor: #ECF4F3;

$errorTextColor: #C56554;
$errorBgColor: #FAF1F0;

// progressbar
$progressBarBgColor: #ebebeb;
$progressBarActiveColor: $primaryColor;

// magic progress
$magicGoalsIconColor: $primaryColor;

// email
$emailNotificationIconColor: $primaryColor;
$emailInputColor: $primaryColor;

// injected payment method
$injectedPaymentMethodActiveColor: #499B92;
$injectedPaymentMethodCheckActiveColor: #ECF4F3;
$injectedPaymentMethodColor: $white;
$injectedPaymentMethodArrowColor: #499B92;
$injectedPaymentMethodDark: none;
$injectedPaymentMethodLight: flex;

// fixed-wrapper
$fixedWrapperBgColor: #23535e;

// trials left
$trialsLeftBadgeColor: $white;
$trialsLeftBadgeBg: $primaryColor;
$trialsLeftTextColor: $primaryColor;

// payment safe
$paymentSafeDark: none;
$paymentSafeLight: inline-block;
$paymentSafeColor: $mineShaft;
