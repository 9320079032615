.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease;
}
iframe {
  border: none;
  width: 100% !important;
}

#container-solidgate-apple-pay {
  width: 100%;
}

#container-solidgate-google-pay {
  margin-top: 16px;
  width: 100%;
}

.payment-form {
  align-items: center;
}
