@import 'src/styles';

.button {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.paypal {
  width: 100%;
  margin: 0;
  opacity: 0;
}

.default {
  width: 100%;
  height: 55px;
}
