@import "src/styles";

.wrapper {
  background: $terminateConfirmBg;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 330px;
  height: 100%;
}

.header {
  width: 100%;
  min-height: 15vh;
  background-color: $terminateConfirmHeaderBg;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__title {
    font-family: $fontFamilyPhilosopher;
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize26;
    line-height: $fontSize32;
    text-align: center;
    color: $terminateConfirmHeaderColor;
    max-width: 330px;
    position: absolute;
  }

  &__blur {
    background: $terminateConfirmBlur;
    transform: rotate(-180deg);
    filter: blur(50px);
    position: absolute;
    width: 100%;
    height: 45px;
    margin-top: -120px;
  }
}

.warning {
  width: 100%;
  padding: 15px;
  background: $terminateConfirmWarningBg;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  margin-bottom: 10px;

  &__icon {
    width: 35px;
    height: 25px;
    margin-right: 10px;
  }

  &__title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize13;
    line-height: $fontSize18;
    color: $terminateConfirmWarningColor;
    width: 100%;
  }
}

.features {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  &__title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: $fontSize15;
    line-height: $fontSize32;
    color: $terminateConfirmFeaturesColor;
    width: 100%;
    text-align: start;
  }
}

.feature {
  width: 100%;
  padding: 15px;
  background: $terminateConfirmFeatureBg;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px;
  border: 2px solid $terminateConfirmFeatureBorder;
  box-shadow: $terminateConfirmFeatureShadow;

  &__icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin-right: 10px;
  }

  &__title {
    font-family: $fontFamilyPhilosopher;
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize20;
    line-height: $fontSize32;
    text-align: start;
    color: $terminateConfirmFeatureTitleColor;
    width: 100%;
  }

  &__subtitle {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize12;
    color: $terminateConfirmFeatureSubtitleColor;
    width: 100%;
    text-align: start;
  }
}

.link {
  margin-top: 30px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $fontSize16;
  line-height: 14px;
  padding: 0;
  text-align: center;
  text-decoration-line: none;
  color: $terminateConfirmButtonBorder;
  border-bottom: 1px solid rgba($terminateConfirmButtonBorder, .6);
}

.button {
  background-color: transparent;
  border: 2px solid $terminateConfirmButtonBorder;
  border-radius: 50px;
  width: 100%;
  max-width: 315px;
  padding: 16px;
  font-family: $fontFamilyPhilosopher;
  font-style: normal;
  font-weight: 700;
  font-size: $fontSize22;
  line-height:$fontSize26;
  text-align: center;
  color: $terminateConfirmButtonColor;
  margin-top: 15px;
}
