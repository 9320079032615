@import 'src/styles';

.banner {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 100%;
    height: 90px;
    background: #2D5668;
    position: absolute;
    padding: 15px;
    top: -18px;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin-left: 10px;
    }

    .discount {
      font-family: $fontFamilyPhilosopher;
      font-weight: 700;
      font-size: $fontSize24;
      line-height: $fontSize28;
      color: $white;
    }

    .title {
      color: $white;
    }
  }
}
