@import 'src/styles';

.container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 16px;
  transform: translate(0, -50%);
  background-color: $plansBadgeColor;
  padding: 8px 20px;
  border-radius: 16px;
  color: $white;
  font-weight: $fwb;
  z-index: 1;
}

.discount {
  font-size: $fontSize14;
  line-height: 1;
}

.timerContainer {
  display: flex;
  align-items: flex-end;
  line-height: 1;
}

.text {
  font-size: $fontSize12;
  white-space: pre-line;
}

.timerText {
  color: $white;
  font-size: $fontSize14;
  margin-left: 5px;
}