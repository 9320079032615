@import 'src/styles';

.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $trans;
  transition: all 0.5s ease-in-out;
  z-index: 2;
  pointer-events: none;

  &.show {
    z-index: 9;
    background: $sideBarLightBackDropBg;
    pointer-events: auto;

    .menu {
      transform: translateX(0px);
    }

  }

  .content {
    padding: 60px 0 0 20px;
    color: $mineShaft;
    a {
      color: inherit;
    }
  }

  .open {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    pointer-events: auto;
  }

  .menu {
    background: $white;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10;
    height: 100%;
    width: 300px;
    transform: translateX(300px);
    transition: transform 0.5s ease-in-out;

    .close {
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }

  &.dark {
    .menu {
      background: $sideBarDarkBgColor;

      .close path {
        fill: $sideBarDarkButtonColor
      }
    }

    .open path {
      fill: $sideBarDarkButtonColor;
    }

    &.show {
      background: $sideBarDarkBackDropBg;

      @media (min-width: 1000px) {
        background: transparent;
      }
    }

    .content {
      color: $white;
    }
  }
}