@import "src/styles";

.root {
  width: 100%;
  margin-top: 16px;
  padding: 0 16px;
  display: inline-block;
  color: $mineShaft;
  font-size: $fontSize14;
  font-weight: 600;
  text-align: center;
  line-height: $fontSize24;
  color: $manageMainColor;

  svg {
    margin-right: 8px;
    vertical-align: middle;

    path {
      stroke: $primaryColor;
    }
  }
}
