@import "src/styles";

body {
  background: $manageBg;
}

.wrapper {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}
